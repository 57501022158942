@use "~styles/text";
@use "~styles/colors";

.tab {
  text-transform: capitalize;
}

.selected {
  @include text.header3(colors.$secondary-dark !important);
}

.indicator {
  background-color: colors.$secondary-dark;
}
