@use "~styles/colors";
@use "~styles/display";

.container {
  padding: 12px;
  border: 1px dashed colors.$text;
  border-radius: 4px;
}

.legend {
  padding: 0 8px;
}

.body {
  @include display.flex($direction: column, $gap: 4px);
}
