@use "~styles/colors";
@use "~styles/display";

.image {
  aspect-ratio: 1.15;
  width: 40%;
  min-height: 154px;
  position: relative;
}

.transition {
  .input {
    transition: all 0.3s ease;
  }

  .overlay {
    transition: all 0.3s ease;
  }

  &:hover {
    .input {
      opacity: 0.3;
    }

    .overlay {
      opacity: 1;
    }
  }
}

.overlay {
  position: absolute;
  opacity: 0;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.button {
  background-color: colors.$background;
  width: 40px;
  height: 40px;
  border-radius: 40px;

  @include display.flex($justify: center, $align: center);
}

.icon {
  fill: colors.$secondary-dark;
}
