@use "~styles/colors";
@use "~styles/display";
@use "~styles/shadow";

.container {
  @include display.flex($direction: row, $justify: flex-end);
}

.pages {
  padding: 8px 0;

  button {
    width: 32px;
    height: 32px;
    font-weight: 400;
    font-size: 12px;

    &[aria-current="true"] {
      font-weight: 700;
      border-radius: 4px;
      color: colors.$secondary-dark;
      background-color: colors.$neutral0 !important;

      @include shadow.shadow-pagination();
    }
  }
}
