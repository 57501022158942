@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.table {
  table-layout: unset;
}
.container {
  @include display.flex($direction: column, $gap: 16px);
}

.loading {
  padding: 0;
  @include display.flex(column);
  min-height: 0;
}

.header {
  @include display.flex(row, center, space-between);
}

.search {
  @include display.flex(row, center, space-between);
}

.tools {
  @include display.flex($direction: row, $align: center, $gap: 16px);
}

.title {
  @include text.body-highlight(colors.$neutral600);
}

.cell {
  @include display.flex(row, center);
}

.date {
  @include display.flex($direction: column);
}
