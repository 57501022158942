@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container,
.wrapper {
  @include display.flex($direction: column, $justify: center);
  height: 100%;
}

.container {
  gap: 8px;
}

.wrapper {
  background-color: colors.$background;
  border-radius: 8px;
  position: relative;
}

.with_image {
  background-color: transparent;
}

.input {
  display: none;
}

.image {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 8px;
}

.placeholder {
  @include display.flex(column, center, center, 8px);
  padding: 12px 0 16px;
}

.text {
  @include display.flex(row, center, center, 4px);
  flex-wrap: wrap;
}

.label {
  @include text.small-text(colors.$neutral500);
}

.link {
  @include text.small-button-text(colors.$text);

  &:hover {
    cursor: pointer;
  }
}

.drag_drop {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
