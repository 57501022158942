@use "~styles/colors";
@use "~styles/display";

.info {
  margin: 20px 0 20px;
}

.pricings {
  @include display.flex($direction: column, $gap: 8px);
}

.dropdown,
.dropdown:hover {
  background: colors.$secondary-dark;
}

.containCreatePricingRuleButton {
  text-align: right;
}

.tabpanel > div {
  padding-top: 0px;
}
