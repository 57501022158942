@use "~styles/display";

.grid {
  @include display.grid($columns: 0.5fr 0.5fr, $auto-rows: minmax(100px, 250px), $gap: 16px);
}

.item {
  position: relative;
}

.image {
  border-radius: 8px;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.trash {
  position: absolute;
  top: 0;
  right: 0;
  padding: 16px;
  cursor: pointer;
}
