.container {
  height: 100%;
  min-height: 100vh;
  padding: 20px 30px 20px;
  background-color: #f7f7fb;
}

.loading {
  display: flex;
  justify-content: center;
  align-items: center;
}
