@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex(column, $gap: 8px);
}

.label {
  @include text.header2(colors.$text);
}
