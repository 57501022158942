@use "~styles/display";

.container {
  @include display.flex($direction: row, $align: flex-start, $gap: 16px);

  & > *:first-child {
    width: 20%;
  }

  & > *:not(:first-child) {
    width: 100%;
  }

  & > *:nth-child(4) {
    width: 40px;
    height: 40px;
    margin-top: 3%;
  }
}
