@mixin shadow-card {
  box-shadow:
    0px 3px 15px 0px rgba(28, 28, 59, 0.05),
    2px 2px 15px 0px rgba(28, 28, 59, 0.05);
}

@mixin shadow-popup {
  box-shadow:
    0px 6px 10px 0px rgba(33, 33, 52, 0.12),
    0px -2px 4px 0px rgba(33, 33, 52, 0.04);
}

@mixin shadow-elevate {
  box-shadow: 8px 8px 15px 0px rgba(66, 90, 88, 1);
}

@mixin shadow-pagination {
  box-shadow: 0px 1px 4px 0px rgba(26, 26, 67, 0.1);
}

@mixin shadow-toast {
  box-shadow:
    0px 4px 12px -4px rgba(33, 33, 52, 0.12),
    0px 16px 32px rgba(33, 33, 52, 0.12);
}
