@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.content {
  @include display.flex($direction: column, $gap: 16px);
}

.top {
  @include display.flex($direction: column, $gap: 4px);
}

.text {
  @include text.header2(colors.$text);
}

.subtext {
  @include text.body(colors.$text);
}

.buttons {
  @include display.flex(row, center, space-between, 12px);

  & > * {
    flex: 0.5;
  }
}
