@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  gap: 24px;
}

.header {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.header2(colors.$text);
}

.versions {
  @include display.grid($columns: 0.7fr 3fr, $gap: 8px);
  align-items: center;
}
