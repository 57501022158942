@use "~styles/display";

.container {
  @include display.flex(column, center, center);
  height: 100vh;
}

.icon {
  width: 80px;
  height: 80px;
  margin-bottom: 20px;
}

.title {
  font-size: 25px;
  margin-bottom: 10px;
  font-weight: bold;
}

.form {
  @include display.flex($direction: column, $gap: 24px);
  width: 30%;
}

.button {
  width: 100%;
}
