.page_section {
  padding: 24px 0;
  display: flex;
}

@media screen and (max-width: 980px) {
  .page_group {
    justify-content: flex-start;
    margin-top: 15px;
  }
}

@media screen and (max-width: 980px) {
  .page_section {
    display: block !important;
  }
}
