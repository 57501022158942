@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 8px);

  &::-webkit-scrollbar {
    width: 2px;
    height: 2px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 4px;
    background-color: colors.$neutral300;
  }
}

.table {
  table-layout: fixed;
}
