@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex(column, $gap: 16px);
}

.header {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.body-highlight(colors.$neutral600);
}

.body {
  @include display.flex($direction: row, $justify: space-between, $gap: 5%);
}

.grid {
  @include display.grid($columns: 1fr 1fr, $auto-rows: min-content, $align-content: space-between);
  width: 100%;
}

.cell {
  @include display.flex(column);
}

.label {
  @include text.table-label(colors.$neutral400);
}

.value {
  @include text.small-button-text(colors.$neutral600);
  margin-right: 15px;
}

.divider {
  margin: 8px -16px 0;
  border-color: colors.$background;
}
