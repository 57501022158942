.container {
  background-color: #f7f7fb;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
}

.title {
  font-size: 20px;
  font-weight: 500;
}

.body {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}

.footer {
  padding: 15px !important;
}

.save_button {
  border: none;
  background: #006486;
  color: white;
  padding: 8px 16px;
  width: 80px;
  height: 32px;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: none;
}

.save_button:hover {
  background: #006486;
}

.save_button:disabled {
  color: white;
  opacity: 0.5;
}
