@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.table {
  table-layout: unset;
}
.container {
  gap: 24px;
}

.header,
.tools {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.header2(colors.$text);
}

.buttons {
  @include display.flex($direction: row, $align: center, $gap: 16px);
}

.body {
  @include display.flex($direction: column, $gap: 16px);
  padding-top: 36px;
}

.date {
  @include display.flex($direction: column);
}
