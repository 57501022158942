@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  padding: 0;
  gap: 24px;
  margin-top: 32px;
}

.content {
  @include display.flex($direction: row, $justify: space-between);
}

.left,
.paper {
  @include display.flex($direction: column, $gap: 16px);
}

.right {
  @include display.flex($direction: column, $align: flex-end, $justify: space-between);
}

.input {
  @include text.table-label(colors.$neutral700);
  padding: 6px 12px;
  margin: 10px 0;
}

.save {
  margin-top: auto;
}

.clear {
  margin-left: auto;
}
