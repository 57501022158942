.page_title {
  display: flex;
  flex-direction: column;
}

.page_title .main_title {
  font-style: normal;
  font-weight: 700;
  font-size: 25px;
  line-height: 42px;
  color: #363656;
}

.page_title .subtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #666686;
}
