@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: row, $align: center, $gap: 8px);

  padding: 8px 24px 8px 12px;
  border: 1px solid colors.$neutral100;
  border-radius: 4px;
}

.input {
  all: unset;

  @include text.small-text(colors.$neutral700);
  min-width: 190px;

  &::placeholder {
    @include text.small-text(colors.$neutral400);
  }
}
