@use "~styles/colors";

.container {
  height: 100vh;
  background-image: linear-gradient(to right, colors.$text, colors.$neutral600);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2vmax;
}

.icon {
  font-size: 5vmax;
  color: colors.$neutral0;
}

.title {
  font-size: 2rem;
  color: colors.$neutral0;
}

.link {
  font-size: 1.4rem;
  cursor: pointer;
  color: colors.$neutral0;
}

.link:hover {
  opacity: 0.8;
}
