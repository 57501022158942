.custom_input .input {
  width: 241px;
  height: 32px;
  background: #ffffff;
  border: 2px solid #006486;
  box-shadow: 0px 1px 4px rgba(33, 33, 52, 0.1);
  border-radius: 4px;
}

.custom_input .iconInput input {
  padding-left: 3px;
  border: unset;
  border-left: 0.5px solid #d9d9d9;
}

.custom_input .iconInput {
  margin: 0px;
  width: 241px;
  height: 32px !important;
  background: #ffffff;
  display: flex;
  justify-content: flex-start;
  border-radius: 4px;
  border: 1px solid #dfdfe8;
}

.custom_input .input.success:focus-visible {
  outline: #006486 auto 0.5px;
}

.custom_input .iconInput:hover {
  border: 1px solid #006486;
}

.img_field {
  width: 15px;
  padding: 0px 10px;
}

.input_field {
  height: 30px;
  width: 86%;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}

.input_field:focus-visible {
  outline: none;
}

@media screen and (max-width: 425px) {
  .custom_input .iconInput {
    width: 188px !important;
    margin-right: 40px !important;
  }
  .input_field {
    width: 45% !important;
  }
}

@media screen and (max-width: 375px) {
  .custom_input .iconInput {
    width: 165px !important;
    margin-left: 6px !important;
  }
  .input_field {
    width: 35% !important;
  }
}
