@use "~styles/display";

.body {
  @include display.flex(row, flex-start, space-between, 3%);
  margin-top: 20px;
}

.menu {
  @include display.flex($direction: column, $gap: 16px);
  flex: 1;
}

.messages {
  flex: 2;
}
