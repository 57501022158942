.container {
  margin-bottom: 32px;
}

.source {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #008ba1;
}
