@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 4px);
}

.label {
  @include display.flex($direction: row, $align: center, $gap: 4px);
}

.optional {
  @include text.small-text(colors.$neutral400);
}

.input {
  all: unset;
  height: 60px;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid colors.$neutral200;

  @include text.header3(colors.$neutral600);
  font-weight: 400;

  &::placeholder {
    @include text.header3(colors.$neutral200);
    font-weight: 400;
  }
}
