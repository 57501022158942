@use "~styles/text";
@use "~styles/colors";
@use "~styles/shadow";
@use "~styles/display";

.paper {
  @include shadow.shadow-popup();
  padding: 16px;
  min-width: 400px;
  max-height: 588px;
  border-radius: 8px;
}

.list {
  @include display.flex(column, $gap: 16px);
  padding: 0;
}

.filter {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.body-highlight(colors.$neutral600);
}

.filter {
  border: 1px solid colors.$neutral200;
  border-radius: 8px;
  padding: 12px 14px 12px 16px;
  cursor: pointer;
}

.label {
  @include text.header3(colors.$neutral600);
  font-weight: 400;
}

.options {
  @include display.flex(column);
  margin-left: 8px;
  overflow: auto;
}

.option {
  @include display.flex(row, center);
  cursor: pointer;
}

.footer {
  @include display.flex(row, center, flex-end, 16px);
}
