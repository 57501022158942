@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.row {
  border-radius: 4px 4px 0 0;
  border-bottom: 1px solid colors.$neutral100;

  &_m {
    height: 36px;
  }

  &_l {
    height: 48px;
  }
}

.cell {
  @include text.table-label(colors.$neutral500);
  background-color: colors.$background;
  padding: 0 4px;

  &:first-of-type {
    border-top-left-radius: 4px;
    padding-left: 8px !important;
  }

  &:last-of-type {
    border-top-right-radius: 4px;
    padding-right: 8px !important;
  }

  &.sortable {
    cursor: pointer;
  }
}

.icon {
  margin-left: 4px;

  &.descending {
    transform: rotateZ(180deg);
  }
}
