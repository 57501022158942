@use "~styles/text";
@use "~styles/colors";
@use "~styles/shadow";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 4px);
}

.selectBusiness {
  position: relative;

  height: 10px !important;
  border: 1px solid colors.$neutral0;
  border-radius: 4px !important;

  @include display.flex($direction: row, $align: center, $gap: 6px);
  @include text.header3(colors.$neutral600);
  font-weight: bold;
  color: colors.$neutral0;

  .checked {
    display: none;
  }

  p {
    font-weight: bold;
  }
}

.select {
  position: relative;

  min-height: 16px !important;
  min-width: 80px !important;
  border: 1px solid colors.$neutral200;
  border-radius: 8px !important;

  @include display.flex($direction: row, $align: center, $gap: 6px);

  & > * {
    @include text.small-text(colors.$neutral600);
    font-weight: 400;
  }

  .checked {
    display: none;
  }
}

.disabled {
  opacity: 0.5;
}

.standardBusiness {
  padding: 10px 16px;
  background-color: colors.$secondary-dark;

  &:focus {
    background-color: colors.$secondary-dark;
  }
}

.standard {
  padding: 10px 16px;
  background-color: colors.$neutral0;

  &:focus {
    background-color: colors.$neutral0;
  }
}

.iconBusiness {
  height: 100%;
  right: 10px;
  top: 0;
  fill: white;
}

.icon {
  height: 100%;
  right: 10px;
  top: 0;
}

.menu {
  top: 8px;
}

.paper {
  @include shadow.shadow-popup();
  border-radius: 8px;
}

.list {
  padding: 0;
}

.item {
  @include display.flex($direction: row, $align: center, $gap: 8px);
  padding: 10px 16px;

  .icon {
    $size: 20px;

    width: $size;
    height: $size;
  }

  .label {
    @include text.small-text(colors.$neutral700);
    line-height: 1.25rem;
  }

  .checked {
    display: block;
  }
}
