@use "~styles/display";

.container {
  @include display.flex($justify: center, $align: center);
  height: 60px;
}

.title {
  font-style: italic;
}
