@use "~styles/display";

.body {
  @include display.flex($direction: column, $gap: 20px);
  margin: 16px 0 24px;
}

.row {
  @include display.flex($direction: row, $gap: 20px);

  & > * {
    flex: 0.5;
  }
}
