@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 8px);
}

.group {
  @include display.flex($direction: row, $gap: 48px);
}

.label {
  @include text.small-button-text(colors.$neutral600);
  line-height: 20px;
}

.option {
  @include display.flex($direction: row, $align: center, $gap: 8px);
  margin: 0;
}

.button {
  width: 24px;
  height: 24px;
}
