@use "~styles/display";

.body {
  margin: 16px 0 24px;
  padding: 0;
}

.container {
  @include display.grid($columns: 1fr 1fr, $gap: 16px);
}
