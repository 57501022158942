@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.row {
  &_m {
    height: 36px;
  }

  &_l {
    height: 42px;
  }
}

.cell {
  @include text.table-label(colors.$neutral700);
  border-bottom: 1px solid colors.$background;
  padding: 0 4px;

  &:first-of-type {
    padding-left: 8px !important;
  }

  &:last-of-type {
    padding-right: 8px !important;
  }
}

.wrapper {
  @include display.flex(row, center, space-between);
  word-wrap: anywhere;
}
