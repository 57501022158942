@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex(row, center, space-between);
  padding: 8px 16px;
}

.container_disabled {
  background-color: colors.$neutral200;
}

.name {
  width: 150px;
}

.price {
  @include display.flex($direction: row, $justify: center, $gap: 8px);
  width: 50px;
}

.time_item {
  @include display.flex(row, center, flex-start, 8px);
}

.block {
  @include display.flex($direction: column, $justify: center, $gap: 10px);
}

.days {
  @include display.flex($direction: row, $align: center, $gap: 4px);
}

.time {
  min-width: 150px;
}

.body {
  @include display.flex($direction: column, $gap: 4px);
  width: 60%;
}

.button {
  margin-left: auto;
}
