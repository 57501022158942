@mixin flex($direction: unset, $align: stretch, $justify: flex-start, $gap: 0) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
  align-items: $align;
  gap: $gap;
}

@mixin grid($columns: auto, $auto-rows: auto, $align-content: normal, $gap: 0) {
  display: grid;
  grid-template-columns: $columns;
  grid-auto-rows: $auto-rows;
  align-content: $align-content;
  gap: $gap;
}
