@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 40px);
  padding: 16px;
}

.switcher_wrapper {
  @include display.flex($direction: row, $align: center);
}

.day_selector {
  @include display.flex($direction: column, $gap: 20px);
}

.days {
  @include display.flex($direction: row, $align: center, $gap: 10px);
}

.time {
  padding-top: 20px;
  @include display.flex($direction: row, $gap: 30px);
}

.fullwidth {
  width: 100%;
}

.date_selector {
  @include display.grid($columns: 1fr 1fr, $gap: 40px 20px);
}

.button {
  margin-left: auto;
}
