@use "~styles/text";
@use "~styles/colors";
@use "~styles/shadow";
@use "~styles/display";

.selected {
  @include shadow.shadow-elevate();
  transform: scale(1.05);
}

.header {
  @include display.flex(row, center, space-between);
}

.left {
  @include display.flex($direction: row, $align: center, $gap: 8px);
}

.divider {
  margin: 16px -16px;
  border-color: colors.$background;
}

.body {
  @include display.flex($direction: row, $align: center, $gap: 24px);
}

.connector {
  @include display.flex($direction: column, $align: center, $gap: 8px);
}

.info {
  @include display.grid($columns: 0.5fr 1fr, $gap: 8px 16px);
}

.field {
  @include display.flex($direction: row, $justify: space-between, $gap: 16px);
  @include text.body(colors.$text);
  font-style: italic;
}

.name,
.title {
  @include text.header3(colors.$text);
  font-weight: 600;
}

.text {
  @include text.header3(colors.$text);
  font-weight: 400;
}
