@use "~styles/colors";
@use "~styles/display";

.container {
  padding: 32px 24px;
  background-color: colors.$background;
}

.body {
  @include display.grid($columns: 1fr 1fr, $gap: 20px);
  margin: 16px 0 24px;
}
