@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  gap: 24px;
}

.header,
.tools {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.header2(colors.$text);
}

.buttons {
  @include display.flex($direction: row, $align: center, $gap: 16px);
}

.body {
  @include display.flex($direction: column, $gap: 16px);
  padding-top: 36px;
}

.connectors {
  @include display.flex(row, center, space-between, 8px);
}

.connector {
  width: 24px;
  height: 24px;
}

.available {
  filter: sepia(100%) hue-rotate(45deg) saturate(200%) brightness(1.2);
}

.charging {
  filter: sepia(100%) hue-rotate(160deg) saturate(200%) brightness(1.3);
}

.offline,
.faulted,
.unavailable {
  filter: grayscale(100%) brightness(2);
}

.preparing,
.finishing {
  filter: sepia(100%) hue-rotate(220deg) saturate(200%) brightness(1.2);
}

.suspendedev {
  filter: hue-rotate(160deg) saturate(250%) brightness(2.2);
}

.suspendedevse {
  filter: sepia(100%) hue-rotate(328deg) saturate(700%) brightness(1.7);
}

.faulted {
  filter: hue-rotate(100deg) saturate(400%) brightness(1.2);
}

.timer {
  justify-content: right;
  max-height: 10px;
  position: absolute;
  right: 0;
  top: 8px;
}
