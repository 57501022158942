@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.label {
  @include text.small-button-text(colors.$neutral600);
}

.data {
  @include text.header3(colors.$neutral600);
}

.connectors {
  @include display.flex($direction: column, $gap: 8px);
  margin-top: 8px;
}

.connector {
  @include display.flex($direction: row, $align: center, $justify: space-between);
}

.type {
  @include display.flex($direction: row, $align: center, $gap: 8px);
}

.image {
  height: 32px;
}
