@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 70px);
}

.header {
  @include display.flex($direction: row, $align: center, $gap: 40px);
  margin-left: 5%;
  margin-top: 40px;
  font-size: 22px;
  font-weight: 600;
}

.title {
  font-size: 43px;
  font-weight: 400;
  line-height: 51px;
  color: colors.$warning-dark;
}

.body {
  @include display.flex($direction: row, $gap: 3%);
  width: 100%;
  padding: 0 30px;
}

.image {
  width: 65%;
}

.right_part {
  width: 25%;
}

.form {
  @include display.flex($direction: column, $gap: 10px);
  margin-top: 60px;
}

.field,
.input {
  width: 100%;
}

.helper {
  @include display.flex($direction: row, $justify: space-between, $align: center, $gap: 40px);
  height: 25px;
}

.forgot_link {
  color: colors.$neutral400;
  font-size: 14px;
  text-decoration: none;
  margin-left: auto;
  cursor: pointer;
}

.button {
  margin-top: 24px;
  width: 100%;
}
