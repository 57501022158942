@use "~styles/text";
@use "~styles/colors";
@use "~styles/shadow";

.root {
  padding: 4px;
}

.menu {
  @include shadow.shadow-popup();
  border-radius: 8px;
}

.list {
  padding-top: 0;
  padding-bottom: 0;
}

.item {
  padding: 8px 12px;
  min-width: 96px;

  &:not(:last-of-type) {
    border-bottom: 1px solid colors.$background;
  }
}

.text {
  @include text.small_text(colors.$neutral500);
}
