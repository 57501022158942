@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  gap: 16px;
}

.header {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.header2(colors.$text);
}
