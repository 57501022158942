.custom_btn {
  display: flex;
  justify-content: center;
  padding: 8px 16px;
  height: 32px;
  border-radius: 4px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  display: flex;
  color: #ffffff;
  border: unset;
  cursor: pointer;
  letter-spacing: 1px;
  min-width: 130px;
  text-transform: none;
}

.custom_btn:disabled {
  opacity: 0.5;
  color: #ffffff;
}

.primary,
.primary:hover {
  background: #006486;
}

.csv,
.csv:hover {
  background: #1c1c3b;
}

.delete,
.delete:hover {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  color: #d02b20;
  background: #fcecea;
  border: 1px solid #f5c0b8;
}

.secondary,
.secondary:hover {
  background: #c00f59;
}
