@use "~styles/text";
@use "~styles/colors";

.container {
  gap: 24px;
}

.title {
  @include text.header2(colors.$text);
}
