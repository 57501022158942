@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  min-height: 100vh;
  padding: 0px 0px 0px 15px;
  background-color: colors.$background;
  @include display.flex(column);
}

.title {
  @include text.header2(colors.$text);
}

.paper {
  @include display.flex($direction: column, $gap: 16px);
  padding-top: 36px;
}
