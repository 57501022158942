@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 16px);
}

.header {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.body-highlight(colors.$neutral600);
}

.button {
  color: colors.$text;
}

.disabled {
  opacity: 0.5;
}
