@use "./colors.scss";
@use "./shadow.scss";

@import url("https://fonts.googleapis.com/css2?family=Lato:wght@300;400;500;600;700");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: "Lato", sans-serif !important;
}

.Toastify__toast {
  @include shadow.shadow-toast();
  border-radius: 8px;

  &--success {
    color: colors.$text;
    background-color: colors.$secondary-light;
  }

  &-error {
    color: colors.$text;
    background-color: colors.$danger-light;
  }
}
