.tools {
  padding: 24px 0;
  margin-top: 40px;
  display: flex;
  gap: 16px;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.filters {
  display: flex;
  gap: 16px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.buttons {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
