.filter_btn {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 4px;
  width: 85px;
  height: 32px;
  background: #ffffff;
  border: 1px solid #dfdfe8;
  border-radius: 4px;
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  color: #363656;
}

.filter_btn:hover {
  border: 1px solid #006486;
}

.menu {
  width: 400px;
}

.menu_item {
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.filter {
  width: 100%;
  max-width: 500px;
  padding: 6px 16px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow: auto;
}

.options_list {
  margin-left: 24px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  max-width: 550px;
  max-height: 150px;
}

.options_list::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

.options_list::-webkit-scrollbar-track {
  background-color: transparent;
}

.options_list::-webkit-scrollbar-thumb {
  background-color: #006486;
  border-radius: 4px;
}
