@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.button {
  padding: 10px 16px;
  border-radius: 8px;
  text-transform: none;
  width: fit-content;

  @include display.flex($align: center, $justify: center);
  @include text.small-button-text(unset);
}

.spiner {
  color: colors.$secondary-dark;
}

.outlined {
  border-width: 1px;
  border-style: solid;
}

.outlined_success {
  color: colors.$secondary-dark;
  border-color: colors.$secondary-dark;
  background-color: colors.$secondary-light;

  &:hover {
    border-color: colors.$secondary-dark;
    background-color: colors.$secondary-light;
  }
}

.contained_success {
  color: colors.$neutral0;
  background-color: colors.$secondary-dark;

  &:hover {
    background-color: colors.$secondary-dark;
  }
}

.outlined_primary {
  color: colors.$text;
  border-color: colors.$text;
  background-color: colors.$neutral0;

  &:hover {
    border-color: colors.$text;
    background-color: colors.$neutral0;
  }
}

.contained_primary {
  color: colors.$neutral0;
  background-color: colors.$text;

  &:hover {
    background-color: colors.$text;
  }
}

.outlined_warning {
  color: colors.$warning-dark;
  border-color: colors.$warning-dark;
  background-color: colors.$neutral0;

  &:hover {
    border-color: colors.$warning-dark;
    background-color: colors.$neutral0;
  }
}

.contained_warning {
  color: colors.$neutral0;
  background-color: colors.$warning-dark;

  &:hover {
    background-color: colors.$warning-dark;
  }
}

.outlined_error {
  color: colors.$danger-dark;
  border-color: colors.$danger-dark;
  background-color: colors.$danger-light;

  &:hover {
    border-color: colors.$danger-dark;
    background-color: colors.$danger-light;
  }
}

.contained_error {
  color: colors.$neutral0;
  background-color: colors.$danger-dark;

  &:hover {
    background-color: colors.$danger-dark;
  }
}
