@use "~styles/display";

.container {
  padding-top: 32px;
}

.body {
  @include display.flex($direction: column, $gap: 20px);
  margin: 16px 0 24px;
}

.row {
  @include display.flex($direction: row, $gap: 20px);

  & > *:first-child {
    flex: 0.4;
  }

  & > *:last-child {
    flex: 0.6;
  }
}
