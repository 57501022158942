@use "~styles/display";

.form {
  @include display.grid($columns: 1fr 1fr, $gap: 16px);
}

.button {
  height: 56px;
  align-self: flex-end;
}
