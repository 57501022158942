@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 16px);
}

.header {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.body-highlight(colors.$neutral600);
}

.wrapper {
  background-color: colors.$neutral0;
  padding: 0;
  min-height: 0;
}

.tools {
  @include display.flex($direction: row, $align: center, $gap: 16px);
}
