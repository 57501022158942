@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: row, $align: center, $gap: 10px);
  cursor: pointer;
}

.container:hover,
.active {
  filter: brightness(100);
}

.title {
  font-size: 15px;
  font-weight: bold;
  color: colors.$neutral400;
}
