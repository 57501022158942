@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  background-color: colors.$neutral0;
  padding: 16px 24px 24px;
}

.header {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.header2(colors.$text);
}

.body {
  @include display.flex($direction: column, $gap: 16px);
  margin: 24px 0 16px;
}

.footer {
  @include display.flex($direction: row, $justify: flex-end, $gap: 16px);
}
