@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.connectors {
  @include display.flex($direction: column, $gap: 8px);
}

.error {
  @include text.small-button-text(colors.$danger-dark);
}
