.modal_content {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.delete_text {
  font-weight: 700;
  font-size: 18px;
}

.btn_container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 10px;
}

.btn {
  flex: 0.5;
  color: #ffffff;
  padding: 8px 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  border: unset;
  border-radius: 4px;
  cursor: pointer;
}

.btn_close {
  background-color: #006486;
}

.btn_delete {
  background-color: #d02b20;
}
