@use "~styles/display";

.row {
  @include display.flex($direction: row, $gap: 16px);
}

.column {
  @include display.flex($direction: column, $gap: 16px);
  flex: 1;
}

.button {
  margin-top: 16px;
}
