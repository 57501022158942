@use "~styles/display";
@use "~styles/colors";
@use "~styles/text";

.container {
  @include display.flex($direction: column, $gap: 8px);
}

.autofill {
  position: relative;
}

.suggestion_box {
  position: absolute;
  width: 100%;
  z-index: 1;
  background-color: colors.$neutral0;
  overflow: auto;
  max-height: 120px;
  border-radius: 5px;
  margin-top: 5px;

  &::-webkit-scrollbar-thumb {
    background-color: lightgray;
    border-radius: 5px;
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 5px;
    width: 4px;
  }
}

.suggestion {
  cursor: pointer;

  &:not(:last-of-type) {
    border-bottom: 1px solid lightgray;
  }
}

.title {
  @include text.header3(colors.$text);
}

.toggle {
  @include text.small-button-text(colors.$neutral500);
  margin-left: 4px;

  cursor: pointer;

  &:hover {
    color: colors.$text;
  }
}
