@use "~styles/display";

.table {
  table-layout: unset;
  overflow: auto;
}

.cell {
  @include display.flex(row, center);
}

.tools {
  @include display.flex(row, center, space-between);
}
