@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.content {
  @include display.flex($direction: column, $gap: 16px);
}

.info {
  @include display.flex($direction: row, $align: center, $gap: 12px);
  padding: 16px;
  width: fit-content;
}

.title {
  @include text.header2(colors.$text);
}

.history {
  @include display.flex($direction: column, $gap: 8px);
}
