@mixin header1($color) {
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.5rem;
  color: $color;
}

@mixin header2($color) {
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: $color;
}

@mixin header3($color) {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  color: $color;
}

@mixin subtitle($color) {
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: $color;
}

@mixin body($color) {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1rem;
  color: $color;
}

@mixin body-highlight($color) {
  @include body($color);
  font-weight: 500;
}

@mixin button-text($color) {
  @include body($color);
  font-weight: 600;
}

@mixin small-text($color) {
  @include body($color);
  font-size: 0.75rem;
}

@mixin small-button-text($color) {
  @include small-text($color);
  font-weight: 600;
}

@mixin table-label($color) {
  @include body($color);
  font-weight: 500;
  font-size: 0.7rem;
}
