@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: row, $align: center, $justify: space-between);
}

.closed {
  justify-content: center;
}

.user {
  @include display.flex($direction: row, $align: center, $gap: 10px);
  cursor: pointer;
}

.image {
  $size: 36px;

  width: $size;
  height: $size;
  border-radius: $size;
}

.name {
  font-weight: bold;
  color: colors.$neutral400;
  text-overflow: ellipsis;
}

.button {
  @include display.flex(row, center, center);
  color: colors.$neutral400;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.name:hover,
.button:hover {
  filter: brightness(100);
}
