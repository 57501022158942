@use "~styles/display";

.row {
  @include display.flex($direction: row, $align: center, $gap: 16px);
}

.label {
  margin-bottom: 10px;
}

.column {
  @include display.flex($direction: column, $gap: 16px);
}

.button {
  margin-right: auto;
}
