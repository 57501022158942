@use "~styles/colors";
@use "~styles/display";
@use "~styles/text";

.data {
  @include display.grid($columns: 1fr, $gap: 16px);
}

.statuses {
  margin: 8px 0;
}

.connectors {
  @include display.flex($direction: column, $gap: 8px);
}

.title {
  @include text.body-highlight(colors.$neutral600);
}
