$neutral0: #ffffff;
$neutral100: #ededf2;
$neutral200: #dfdfe8;
$neutral300: #dbdbe6;
$neutral400: #9090b0;
$neutral500: #666686;
$neutral600: #525272;
$neutral700: #363656;

$background: #f5f5f7;
$text: #041a44;

$primary-light: #1c1c3b;
$primary-dark: black;

$secondary-light: #fff8e6;
$secondary-dark: #fabc19;

$warning-light: #fff1dd;
$warning-dark: #ff7d22;

$danger-light: #ffe8eb;
$danger-dark: #e62930;
