@use "~styles/colors";
@use "~styles/display";

.paper {
  overflow-y: unset;
}

.handle {
  position: absolute;
  right: -16px;
  top: 0;
  bottom: 0;
}

.content {
  height: 100%;
  position: relative;
  padding: 20px 16px 16px;
  background-color: colors.$primary-dark;
  @include display.flex($direction: column, $justify: space-between);
}

.top {
  @include display.flex($direction: column, $gap: 16px);
  max-height: calc(100vh - 100px);
}

.navbar {
  height: 100%;
  padding: 0;
  overflow: auto;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: gray;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb {
    background: colors.$neutral0;
    border-radius: 4px;
  }
}

.subnav {
  padding: 0 16px;
  margin: -6px 0 4px;
}
