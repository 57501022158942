@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 16px);
}

.header {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.body-highlight(colors.$neutral600);
}

.tools {
  @include display.flex($direction: row, $align: center, $gap: 16px);
}

.date {
  @include display.flex(column);
}
