@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.table {
  table-layout: unset;
}

.container {
  @include display.flex($direction: column, $gap: 16px);
}

.header {
  @include display.flex(row, center, space-between);
}

.search {
  @include display.flex(row, center, space-between);
}

.tools {
  @include display.flex($direction: row, $align: center, $gap: 16px);
}

.title {
  @include text.body-highlight(colors.$neutral600);
}

.cell {
  @include display.flex(row, center);
}
