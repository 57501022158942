@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  gap: 24px;
}

.header {
  @include display.flex(row, center, space-between);
}

.data {
  @include display.flex(row, center, $gap: 16px);
}

.title {
  @include text.header2(colors.$text);
}

.body {
  @include display.flex($direction: column, $gap: 16px);
  padding-top: 36px;
}
