@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: row, $align: center, $gap: 8px);
}

.root {
  padding: 0;
  width: 46px;
  height: 26px;
}

.base {
  color: unset;
  background-color: transparent;
  padding: 3px;

  &:hover,
  &:focus {
    color: unset !important;
    background-color: transparent !important;
  }

  &.checked + .track {
    background-color: colors.$secondary-dark;
    opacity: 1;
  }
}

.thumb {
  background-color: colors.$neutral0;
}

.track {
  background-color: colors.$neutral200;
  border-radius: 14px;
  opacity: 1;
}
