@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($align: center, $justify: center);
  aspect-ratio: 1;
  width: 60px;
  cursor: pointer;
  height: 30px;
  border-radius: 4px;
  border: 2px solid colors.$neutral300;
}

.text {
  font-weight: normal;
  font-size: 18px;
  color: colors.$neutral300;
}

.container_compact {
  cursor: default;
  width: 40px;
  height: 24px;
  border: 1px solid transparent;
}

.text_compact {
  font-weight: normal;
  font-size: 14px;
  color: colors.$neutral300;
}

.container_selected {
  border-width: 2px;
  border-color: colors.$secondary-dark;
}

.container_selected_compact {
  border-width: 1px;
}

.text_selected {
  color: colors.$secondary-dark;
}
