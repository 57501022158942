@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.button {
  @include display.flex($direction: row, $align: center, $gap: 4px);
  padding: 8px 16px;
  border: 1px solid colors.$neutral200;
  border-radius: 4px;
  cursor: pointer;
}

.label {
  @include text.small-text(colors.$neutral700);
  font-weight: 700;
}
