@use "~styles/colors";
@use "~styles/display";

.container {
  min-height: 100vh;
  padding: 32px 24px;
  background-color: colors.$background;
  @include display.flex(column);
}

.loading {
  @include display.flex(column, center, center);
}
