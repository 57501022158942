@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex($direction: column, $gap: 4px);
}

.label {
  @include text.small-button-text(colors.$neutral600);
}

.error {
  @include text.small-button-text(colors.$danger-dark);
}

.label,
.error {
  padding: 0 8px;
}
