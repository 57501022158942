@use "~styles/text";
@use "~styles/colors";
@use "~styles/display";

.header {
  @include display.flex($direction: column, $gap: 6px);
}

.top {
  @include display.flex($direction: row, $align: center, $gap: 8px);

  &:hover {
    cursor: pointer;
  }
}

.back {
  @include text.small-text(colors.$text);
}

.bottom {
  @include display.flex(row, center, space-between);
}

.title {
  @include text.header2(colors.$text);
}

.buttons {
  @include display.flex($direction: row, $align: center, $gap: 16px);
}
