@use "~styles/display";
@use "~styles/colors";

.container {
  padding: 32px 24px;
  background-color: colors.$background;
}

.row {
  @include display.flex($direction: row, $gap: 20px);

  & > *:first-child {
    flex: 0.4;
  }

  & > *:last-child {
    flex: 0.6;
  }
}
