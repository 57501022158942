.info {
  margin: 20px 0 20px;
}

.pricings {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.dropdown,
.dropdown:hover {
  background: #82af60;
}

.containCreatePricingRuleButton {
  text-align: right;
}

.tabpanel > div {
  padding-top: 0px;
}
