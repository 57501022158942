@use "~styles/colors";
@use "~styles/display";

.container {
  height: 100vh;
  background-color: colors.$background;
}

.content {
  padding: 50px 30px 0;
}

.header {
  @include display.flex(row, center, space-between, 10px);
  padding: 12px;
}

.header_part {
  @include display.flex($direction: row, $align: center, $gap: 10px);
}

.body {
  @include display.flex($direction: column, $gap: 10px);
  padding: 12px;
}

.buttons {
  @include display.flex($direction: row, $justify: flex-end, $gap: 12px);
}

.notification_btn {
  margin-top: 24px;
}

.row {
  @include display.flex($direction: row, $align: center, $gap: 30px);
  padding: 8px 0;
}

.label {
  font-size: 12px;
  width: 120px;
}

.data {
  font-size: 15px;
  font-weight: 700;
}
