@use "~styles/colors";
@use "~styles/display";

.container {
  @include display.flex(column, center, center, 20px);
  width: 100vw;
  height: 100vh;
}

.image {
  width: 80px;
  height: 80px;
}

.header {
  @include display.flex($direction: column, $gap: 10px);
}

.title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
}

.subtitle {
  font-size: 15px;
  text-align: center;
}

.form {
  @include display.flex($direction: column);
  width: 350px;
}

.button {
  margin-top: 24px;
  width: 100%;
}
